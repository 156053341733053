import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Api from "./Api";
import { Button, TextField, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
});

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function UpdatePersonalInfo() {
  const [personalInfo, setPersonalInfo] = useState({});
  const [code, setCode] = useState("");

  const query = useQuery();
  const specialCodeFromURL = query.get("code");

  useEffect(() => {
    if (specialCodeFromURL) {
      setCode(specialCodeFromURL);
    }
  }, [specialCodeFromURL]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const updatePersonalInfo = async () => {
    try {
      const response = await Api.put(`/update-info/${code}`, personalInfo);
      alert(response.data.message);
    } catch (error) {
      console.error("Error updating personal info:", error);
    }
  };

  return (
    <div>
      <Typography variant="h4" mb={2}>
        Update Personal Info
      </Typography>
      <FormContainer>
        <TextField
          label="Name"
          name="name"
          variant="outlined"
          onChange={handleInputChange}
        />
        <TextField
          label="Email"
          name="email"
          variant="outlined"
          onChange={handleInputChange}
        />
        <Button variant="contained" color="primary" onClick={updatePersonalInfo}>
          Update Personal Info
        </Button>
      </FormContainer>
    </div>
  );
}

export default UpdatePersonalInfo;
