import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "./Api";
import { Typography } from "@mui/material";

function UserInfo() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await Api.get(`/get-info/${userId}`);
        console.log("Fetched user data:", response.data); // Keep this line to log the fetched data
        setUser(response.data.user); // Update this line to set the user object
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUser();
  }, [userId]);

  return (
    <div>
      <Typography variant="h4" mb={2}>
        User Information
      </Typography>
      {user && (
        <>
          <Typography variant="body1">
            Name: {user.personalInfo.name}
          </Typography>
          <Typography variant="body1">
            Email: {user.personalInfo.email}
          </Typography>
        </>
      )}
    </div>
  );
}

export default UserInfo;