import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Link,
} from "react-router-dom";
import Api from "./Api";
import { Button, TextField, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import QRCode from "qrcode.react";
import UserInfo from "./UserInfo";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import Layout from "./components/Layout";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
});


function Welcome() {
  return (
    <Box>
      <Typography variant="h2" color="primary">
        Welcome to Our Website
      </Typography>
      <Typography variant="h4" color="secondary">
        Securely Store and Share Your Personal Information
      </Typography>
      <Typography variant="body1">
        Our platform allows you to create a unique QR code for your personal
        information. You can share this code with others, who can scan it to
        view your information. Update your information easily using your
        Special Code.
      </Typography>
      <Box mt={2}>
        <Link to="/create">
          <Typography variant="h6" color="primary">
            Get Started
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

function CodeAndUserCreation() {
  const [personalInfo, setPersonalInfo] = useState({});
  const [userId, setUserId] = useState("");
  const [code, setCode] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPersonalInfo((prevState) => ({ ...prevState, [name]: value }));
  };

  const generateCode = async () => {
    try {
      const response = await Api.post("/generate-code");
      setCode(response.data.code);
      setUserId(response.data.userId);
    } catch (error) {
      console.error("Error generating code:", error);
    }
  };



  const savePersonalInfo = async () => {
    try {
      const response = await Api.post(`/add-info/${userId}`, personalInfo);
      alert(response.data.message);
    } catch (error) {
      console.error("Error saving personal info:", error);
    }
  };


  return (
    <div>
      <Typography variant="h4" mb={2}>
        Generate Special Code
      </Typography>
      <FormContainer>
        <TextField
          label="Name"
          name="name"
          variant="outlined"
          onChange={handleInputChange}
        />
        <TextField
          label="Email"
          name="email"
          variant="outlined"
          onChange={handleInputChange}
        />
       <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={generateCode}
          onTouchStart={generateCode}
        >
          Generate Code
      </Button>
        {code && (
          <div>
            <Typography variant="h6">Your Special Code:</Typography>
            <Typography variant="h5" color="secondary">
              {code}
            </Typography>
            <Typography variant="h6">Scan Green QR Code to Access Info:</Typography>
            <Typography variant="body1">
              <a href={`${window.location.origin}/qr-code-data/${userId}`} target="_blank" rel="noopener noreferrer">
                {`${window.location.origin}/qr-code-data/${userId}`}
              </a>
            </Typography>
            <QRCode
              value={`${window.location.origin}/qr-code-data/${userId}`}
              fgColor="#4CAF50"
            />
            <Typography variant="h6" mt={2}>
              Scan Red QR Code to Update Info:
            </Typography>
            <Typography variant="body1">
              <a href={`${window.location.origin}/update-personal-info?code=${code}`} target="_blank" rel="noopener noreferrer">
                {`${window.location.origin}/update-personal-info?code=${code}`}
              </a>
            </Typography>
            <QRCode
              value={`${window.location.origin}/update-personal-info?code=${code}`}
              fgColor="#F44336"
            />
          </div>
        )}
        <Button variant="contained" color="primary" onClick={savePersonalInfo}>
          Save Personal Info
        </Button>
      </FormContainer>
    </div>
  );
}


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/create" element={<CodeAndUserCreation />} />
          <Route path="/qr-code-data/:userId" element={<UserInfo />} />
          <Route path="/update-personal-info" element={<UpdatePersonalInfo />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
