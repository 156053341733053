import React from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/system";

const Header = styled("header")({
  backgroundColor: "#6a1b9a",
  color: "white",
  padding: "1rem 0",
  textAlign: "center",
  fontSize: "1.5rem",
});

const MainContent = styled(Container)({
  paddingTop: "2rem",
  paddingBottom: "2rem",
});

const Layout = ({ children }) => {
  return (
    <div>
      <Header>Personal Info App</Header>
      <MainContent>{children}</MainContent>
    </div>
  );
};

export default Layout;
